import React, { useState, useEffect } from 'react';
import Imge from './Imge';
import Imges from './Imges';
function Class() {
  const [isAuthorized, setIsAuthorized] = useState(null);
  const [userIP, setUserIP] = useState('');

  useEffect(() => {
    fetch('https://api.ipify.org?format=json')
      .then(response => response.json())
      .then(data => {
        const ipAddress = data.ip;
        setUserIP(ipAddress);

        return fetch(`https://168.u168phone.com/check-ip?ipAddress=${ipAddress}`);
      })
      .then(response => response.json())
      .then(data => {
        if (data.message === "อนุญาต") {
            setIsAuthorized(true);
        } else {
            setIsAuthorized(false);
        }
      })
      .catch(error => {
          console.error('There was a problem with the fetch operation:', error.message);
          setIsAuthorized(false);
      });
  }, []);

  if (isAuthorized === null) {
    return <div>กำลังตรวจสอบ...</div>;
  }

  if (!isAuthorized) {
     return <div> <p className='login'> <Imges />  </p></div>;
  }

  return (
    <div>
      <>  </>  
      <Imge />



    </div>
  );
}

export default Class;
