import { useState, useEffect } from "react";
import Axios from "axios";

function App() {

  const [ipuser, setIpuser] = useState([]);
  const [name, setName] = useState("");

// กำหนดให้ร้องขอข้อมูลทุก 5 วินาที
const POLLING_INTERVAL = 1000;

useEffect(() => {
    const fetchIpUserData = () => {
        Ipuser();
    };

    // ใช้ setTimeout ร่วมกับ recursive function ในการเรียกฟังก์ชันทุก 5 วินาที
    const polling = setTimeout(() => {
        fetchIpUserData();
    }, POLLING_INTERVAL);

    // ใช้ clearInterval ในการยกเลิกการทำงานของ setTimeout เมื่อ component ถูก unmount
    return () => clearInterval(polling);
}, [ipuser]); // หาก ipuser เปลี่ยนแปลง มันจะเรียกใช้ useEffect ใหม่

  const Ipuser = () => {
    Axios.get("https://168.u168phone.com/api")  //-------------------------API เก็บไอพี
      .then((response) => {
        setIpuser(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const deleteIpuser = (id) => {
    Axios.delete(`https://168.u168phone.com/delete/${id}`)
      .then((response) => {
        setIpuser(ipuser.filter((val) => val.id !== id));
      })
      .catch((error) => {
        console.error("Error deleting data:", error);
      });
  };


  
  
return (
    <div className="box">
      <div className="text-center" ><h2>จำนวน {ipuser.length}</h2></div>
      <table className="table table-hover">
        <thead>
          <tr>
            <th> ID</th>
            <th> IP </th>
            <th> Time </th>
            <th> Actions </th>
          </tr>
        </thead>
        <tbody>
          {ipuser.map((val) => (
            <tr key={val.id}>
              <td>{val.id}</td>
              <td>{val.ip}</td>
              <td>{val.timestamp}</td>
              <td><button className="btn btn-danger" onClick={() => deleteIpuser(val.id)}>Delete</button></td>
            </tr>
          ))}
        </tbody>
      </table>

    </div>
  );
  
}

export default App;
